<template>
  <div class="wrapper clearfix">
    <div class="session ">
      <div class="session-title">服务商信息</div>
      <div class="service-list clearfix">
        <div class="service-item fl" v-for="(item, index) in 12" :key="index" @click="go('DTDetails')">
          <div class="logo">暂无LOGO</div>
          <div class="item-info">
            <div class="name">CI&T</div>
            <div class="tags clearfix">
              <div class="tag fl">财务数字化</div>
              <div class="tag fl">电子行业</div>
              <div class="tag fl">参考价10W</div>
              <div class="tag fl">工期短</div>
            </div>
          </div>
          <div class="arrow">
            <img src="../../assets/img/digitalTransformation/box_icon_more.png" alt="" />
          </div>
        </div>
      </div>
       <el-pagination
       background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-size="12"
        layout="total, prev, pager, next, jumper"
        :total="360"
        style="margin-top:16px"
      >
      </el-pagination>
    </div>
     
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: 'IBM',
      tags: ['财务数字化', '电子行业', '参考价10W', '工期短'],
      currentPage4: 4,
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    go(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.session {
  margin-top: 16px;
  margin-bottom: 30px;
  padding: 0 20px 30px;
  background-color: #fff;
}
.session-title {
  font-size: 26px;
  color: #333333;
  line-height: 74px;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding-left: 14px;
  position: relative;
  font-weight: 600;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 28px;
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
  }
}
.service-item {
  /* height: 136px; */
  margin-right: 17px;
  margin-top: 16px;
  background-image: linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
  border-radius: 4px;
  width: calc((100% - 34px) / 3);
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
}

.service-item:nth-of-type(3n) {
  margin-right: 0;
}

.service-item .logo {
  width: 104px;
  height: 104px;
  background-color: #fff;
  font-size: 16px;
  color: #6d8ecd;
  font-weight: 600;
  text-align: center;
  line-height: 104px;
  margin-right: 24px;
}

.arrow {
  width: 24px;
  height: 24px;
  background-image: linear-gradient(90deg, #82a6ff 0%, #255ada 100%);
  border-radius: 12px 0 0 12px;
  position: absolute;
  right: 0;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrow img {
  margin-left: 6px;
}
.item-info {
  width: calc(100% - 124px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 104px;
}

.item-info .name {
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  margin-bottom: 16px;
  font-weight: 600;
}

.item-info .tag {
  background: rgba(37, 90, 218, 0.2);
  border-radius: 4px;
  margin-right: 8px;
  margin-top: 8px;
  height: 24px;
  width: 96px;
  font-size: 12px;
  color: #255ada;
  text-align: center;
  line-height: 24px;
}
.list-item {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin-top: 16px;
}
</style>

